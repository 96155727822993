import { Dispatch, MutableRefObject } from 'react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Order } from '@Types/cart/Order';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { CustomerProps, EditOrder, WarehouseProps } from 'composable/helpers/utils/use-user-utils/types';
import { ShamrockUser } from 'frontastic/actions/shamrockApi/types';

export type UserState = {
  accessToken?: string;
  refreshToken?: string;
  expiresOn?: number;
  ctUser?: CustomerProps;
  shamrockUser?: ShamrockUser;
  activeAccount?: Partial<AsAssociateBusinessUnitResult>;
  activeWarehouse?: WarehouseProps;
  accountList?: AsAssociateBusinessUnitResult[];
  loading: boolean;
  isSuperUser: boolean;
};

export enum UserGlobalStateActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_LOADING = 'SET_LOADING',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_IS_SUPER_USER = 'SET_IS_SUPER_USER',
}

export type UserGlobalAction = {
  type: UserGlobalStateActions;
  payload?: Partial<UserState>;
};

export type UseUserGlobalResponse = {
  state: UserState;
  dispatch: Dispatch<UserGlobalAction>;
  checkIfLoggedIn: () => Promise<void>;
  logout: (hardLogout?: boolean, message?: string) => Promise<void>;
  switchAccount: (accountKey: string) => Promise<void>;
  canViewProductCatalog: () => boolean;
  azureRedirectUrl: string;
  // Edit Order Mode
  editOrder: EditOrder;
  handleDisableEditOrder: () => void;
  handleEnableEditOrder: (order: OrderDetail) => void;
  handleChangeOrderEditing: (order: OrderDetail) => void;
  updateOrder: () => Promise<void>;
  setUpdatingOrder: (isUpdatingOrder: boolean) => void;
  persistedSplittedEditOrder: MutableRefObject<{ parentOrderNumber: string; splittedOrder: Order }>;
  updatingOrder: boolean;
  findOriginalOrderFromCurrentEditingOrder: (sku: string) => OrderDetailLineItem;
};
