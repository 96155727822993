import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { FEATURE_FLAG_GEOCODE_CUTOFF } from 'composable/components/general';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { hasCreditOnHoldInfo } from 'composable/helpers/utils/user-utils';
import { isBefore } from 'date-fns';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { FormattedDeliveryOption } from 'helpers/services/shamrock';
import { MAX_DELIVERY_DATES, deliveryOptions } from 'helpers/utils/fetchers';
import { CutoffsGlobalStateActions } from 'hooks/global/use_privateCutoffsGlobal/types';
import every from 'lodash/every';
import values from 'lodash/values';
import useSWR, { useSWRConfig } from 'swr';
import { calculateLatestDeliveryDate } from 'utils/checkout/calculateLatestDeliveryDate';
import { useGetExtraProductData } from './useGetExtraProductData';

const WILL_CALL = 'WillCall';
const A_LA_CARTE = 'ALaCarte';
const BASE_URL = `${SHAMROCK_API_URL}/delivery-options/search`;

type ProductsForDeliveryWindow = {
  productNumber: string;
  quantity: number;
};

export const useGetDeliveryWindows = (
  products: ProductsForDeliveryWindow[],
  orderNumber?: string,
  eligibleForPickup?: boolean,
  eligibleForALaCarte?: boolean,
  placingOrder: boolean = false,
  shouldUpdateGeocodeCutoff: boolean = false,
) => {
  const { state: user, logout } = useGlobal().useUserGlobal;
  const { dispatch: dispatchCutoffs } = useGlobal().useCutoffsGlobal;
  const { activeWarehouse, accessToken, activeAccount } = user;
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const skus = products.map((product) => product.productNumber);
  const { cache } = useSWRConfig();
  const { extraProductData, isLoadingExtraData } = useGetExtraProductData(skus, user, cache);
  const [aLaCarteOptions, setALaCarteOptions] = useState([]);
  const [pickupOptions, setPickupOptions] = useState([]);
  const { asPath } = useRouter();
  const [responseTime, setResponseTime] = useState<number>(0); 

  const payload = useMemo(() => {
    if (!activeWarehouse || !accessToken || !activeAccount || isLoadingExtraData || !extraProductData.length) {
      return null;
    }

    // Get latest delivery date from product data
    let latestDeliveryDate = calculateLatestDeliveryDate(extraProductData);
    const now = new Date();
    const todayDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

    if (isBefore(latestDeliveryDate, todayDate)) {
      latestDeliveryDate = null;
    } else {
      // If in the future, update date to be the end of the previous day
      latestDeliveryDate.setUTCDate(latestDeliveryDate.getUTCDate() - 1);
      // Set time to the end of the day in UTC (23:59:59.999)
      latestDeliveryDate.setUTCHours(23, 59, 59, 999);
    }

    return {
      warehouseNumber: activeWarehouse?.warehouseNumber,
      businessUnitName: activeWarehouse?.businessUnit,
      businessSegmentName: activeWarehouse?.businessSegment,
      customerNumber: activeAccount?.key,
      products,
      startingAfter: latestDeliveryDate?.toISOString(),
    };
  }, [accessToken, extraProductData, isLoadingExtraData, products, activeAccount, activeAccount?.key, activeWarehouse]);

  const isValidPayload =
    !!payload && every(values(payload), (val) => val !== '' && !(Array.isArray(val) && val.length === 0));
  const hasCreditOnHold = hasCreditOnHoldInfo(activeAccount);
  const readyToFetch =
    isValidPayload &&
    !hasCreditOnHold &&
    products?.length > 0 &&
    ((!placingOrder && !isLoadingExtraData && [routes.CHECKOUT, routes.EDIT_ORDER_CHECKOUT].includes(asPath)) ||
      shouldUpdateGeocodeCutoff);

  const {
    data: deliveryOptionsData,
    isLoading: isLoadingDeliveryOptions,
    isValidating: isValidatingDeliveryOptions,
  } = useSWR(
    () => {
      if (readyToFetch) {
        let deliveryMethod;
        if (eligibleForPickup && !eligibleForALaCarte) {
          deliveryMethod = WILL_CALL;
        } else if (eligibleForALaCarte && !eligibleForPickup) {
          deliveryMethod = A_LA_CARTE;
        }

        return [
          BASE_URL,
          {
            ...getObjectPayload(payload, orderNumber, deliveryMethod),
          },
        ];
      }
      return null;
    },
    async ([url, payload]) => {
      const startTime = performance.now(); 
      const data = await deliveryOptions([
        url,
        { ...payload, accessToken, logout, toast, toastMessage: formatMessage({ id: 'app.generic.error' }) },
      ]);

      const endTime = performance.now(); 
      setResponseTime(endTime - startTime); 

      return data;
    },
    { ...SWR_OPTIONS, revalidateIfStale: true, errorRetryCount: 2 },
  );

  useEffect(() => {
    if (!isLoadingDeliveryOptions) {
      const aLaCarteOptionsList = deliveryOptionsData?.deliveryOptions
        .filter((option) => option?.deliveryType === A_LA_CARTE)
        .slice(0, MAX_DELIVERY_DATES);

      const pickupOptionsList = deliveryOptionsData?.deliveryOptions
        .filter((option) => option?.deliveryType === WILL_CALL)
        .slice(0, MAX_DELIVERY_DATES);

      if (aLaCarteOptionsList && aLaCarteOptionsList.length > 0) {
        setALaCarteOptions(aLaCarteOptionsList);
      }
      if (pickupOptionsList && pickupOptionsList.length > 0) {
        setPickupOptions(pickupOptionsList);
      }

      // update global cutoffs with geocode date and time
      if (FEATURE_FLAG_GEOCODE_CUTOFF && deliveryOptionsData?.geocodeCutoffDate) {
        const geocodeTime = deliveryOptionsData?.geocodeCutoffTime ?? '12:00:00';
        const extendedCutoff = `${deliveryOptionsData?.geocodeCutoffDate}T${geocodeTime}.000Z`;

        dispatchCutoffs({
          type: CutoffsGlobalStateActions.SET_EXTENDED_CUTOFFS,
          payload: { extendedCutoff: extendedCutoff },
        });
      }
    }
  }, [deliveryOptionsData?.deliveryOptions, isLoadingDeliveryOptions, activeAccount, activeAccount?.key]);

  return {
    // @TODO add this value to the useGlobal Cutoffs
    geocodeCutoffDate: deliveryOptionsData?.geocodeCutoffDate,
    geocodeCutoffTime: deliveryOptionsData?.geocodeCutoffTime,
    deliveryWindows: {
      [KEY_A_LA_CARTE]: (aLaCarteOptions as FormattedDeliveryOption[]) || [],
      [KEY_PICK_UP]: (pickupOptions as FormattedDeliveryOption[]) || [],
    },
    deliveryMinimums: deliveryOptionsData?.deliveryMinimums || [],
    isLoading: {
      [KEY_A_LA_CARTE]: isLoadingDeliveryOptions || isValidatingDeliveryOptions,
      [KEY_PICK_UP]: isLoadingDeliveryOptions || isValidatingDeliveryOptions,
    },
    responseTime
  };
};

//Private functions
const getObjectPayload = (payload: any, orderNumber: string, method?: 'WillCall' | 'ALaCarte') => {
  const payloadData = {
    payload: {
      ...payload,
      orderNumber,
    },
  };

  if (method) {
    payloadData.payload.method = method;
  }

  return payloadData;
};
