import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import { CART_HAS_BEEN_SUBMITTED_ID, TOAST_ICON } from 'composable/components/general';
import { SELECTED_BUSINESS_UNIT_KEY } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { FeatureFlags } from 'utils/featureFlags';
import { useFormat } from './useFormat';

export const useSubmittedCartHandler = (cart: Cart | null, refetchCart: (cartId?: string) => Promise<Cart>) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const cartId = useRef<string | null>(cart?.cartId);
  const lineItems = cart?.lineItems;
  const [selectedBU] = useLocalStorage(SELECTED_BUSINESS_UNIT_KEY);
  const activeAccount = useRef(selectedBU);
  const { asPath } = useRouter();

  function fireToast() {
    if (FeatureFlags.TOAST_CART_SUBMITTED_BY_SOMEONE_ELSE && !toast.isActive(CART_HAS_BEEN_SUBMITTED_ID)) {
      toast({
        duration: 5000,
        id: CART_HAS_BEEN_SUBMITTED_ID,
        status: 'warning',
        title: formatMessage({ id: 'cart.hasBeenSubmitted' }),
        icon: TOAST_ICON.warning,
      });
    }
  }

  async function resetSubmittedCart() {
    fireToast();
    const newCart = await refetchCart();
    cartId.current = newCart?.cartId;
  }

  useEffect(() => {
    if (cart?.cartId && cart.cartId !== cartId.current) {
      cartId.current = cart.cartId;
    }
  }, [cart?.cartId]);

  useEffect(() => {
    if (selectedBU !== activeAccount.current) {
      activeAccount.current = selectedBU;
      return;
    }
    if (lineItems?.length > 0)
      refetchCart(cartId.current).then((res) => {
        if (res.cartId !== cartId.current) {
          resetSubmittedCart();
        }
      });
  }, [asPath]);

  return { resetSubmittedCart };
};
