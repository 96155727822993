import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGlobal } from 'components/globalProvider';
import { SHAMROCK_API_QUERY_LIMIT, SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { createProductExtraQueryParams } from 'helpers/utils/createExtraProductDataPayload';
import chunk from 'lodash/chunk';
import useSWR, { useSWRConfig } from 'swr';
import { getProductExtendedData } from 'frontastic/actions/shamrockApi';
import { useShouldExecuteApi } from './useHandleCancelApiCall';

export const useProductExtraDataExecutable = (skus: string[], page?: string) => {
  const { isPrintBack, useUserGlobal } = useGlobal();
  const { state: user, logout } = useUserGlobal;
  const { accessToken, activeWarehouse, activeAccount, loading: isLoadingUserData, accountList } = user;
  const { cache } = useSWRConfig();
  const listOfAccountNumbers = accountList?.map((account) => account.key) ?? [];
  // Generate query parameters for the product data request
  const queryParams = createProductExtraQueryParams(
    accessToken,
    activeAccount?.key,
    activeWarehouse,
    listOfAccountNumbers,
    page,
  );

  const fetcher = async () => {
    const chunks = chunk(skus, SHAMROCK_API_QUERY_LIMIT);

    const productExtraDataPromises = chunks.map(async (chunk) => {
      const payload = {
        ...queryParams,
        productNumbers: chunk,
      };

      const productExtraData = await getProductExtendedData(payload, cache);

      return productExtraData?.products ?? [];
    });

    const shamrockResponse = await Promise.all(productExtraDataPromises);
    return [].concat(...shamrockResponse);
  };

  const { data, isLoading, isValidating } = useSWR(
    () =>
      accessToken && skus.length > 0 && !isPrintBack
        ? [JSON.stringify(skus), activeAccount?.key, activeWarehouse, listOfAccountNumbers, page]
        : null,
    fetcher,
    {
      ...SWR_OPTIONS,
      errorRetryCount: 2,
    },
  );

  return {
    extraProductData: (data || []) as ExtraProductData[],
    isLoadingShamrockData: isLoading || isLoadingUserData || isValidating,
    isValidatingShamrockData: isValidating,
  };
};

//eslint-disable-next-line
export const useProductExtraData = useShouldExecuteApi(useProductExtraDataExecutable, {
  extraProductData: [],
  isLoadingShamrockData: false,
  isValidatingShamrockData: false,
});
