import { useGlobal } from 'components/globalProvider';
import { getServerAzureLogoutUrl, refreshAccessToken } from 'composable/helpers/utils/use-user-utils';
import useSWR from 'swr';
import { UserGlobalStateActions } from './global/use_privateUserGlobal/types';
import { ACCESS_TOKEN } from 'composable/helpers/constants';
import { useMemo } from 'react';
import { validateAccessToken } from './global/use_privateUserGlobal/utils';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';

const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 5; // 5 minutes

export const useRefreshToken = () => {
  const { encryptionKey, useUserGlobal } = useGlobal();
  const {
    state: { refreshToken },
    dispatch,
  } = useUserGlobal;

  const isValidRefreshToken = useMemo(() => validateAccessToken(refreshToken), [refreshToken]);

  useSWR(
    isValidRefreshToken ? 'refreshToken' : null,
    () =>
      refreshAccessToken(refreshToken, getServerAzureLogoutUrl(), encryptionKey, (getTokensResponse) => {
        window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
        dispatch({
          type: UserGlobalStateActions.SET_ACCESS_TOKEN,
          payload: {
            accessToken: getTokensResponse.access_token,
            refreshToken: getTokensResponse.refresh_token,
            expiresOn: getTokensResponse.expires_on,
          },
        });
      }),
    { ...SWR_OPTIONS, refreshInterval: REFRESH_TOKEN_INTERVAL, refreshWhenHidden: true, errorRetryCount: 2 },
  );
};
