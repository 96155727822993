import { useMemo } from 'react';
import { AsAssociateBusinessUnitResult, DeliverySchedule, DeliveryPlan } from '@Types/business-unit/BusinessUnit';
import { useGlobal } from 'components/globalProvider';
import { FEATURE_FLAG_GEOCODE_CUTOFF, FEATURE_FLAG_SOFT_CUTOFF } from 'composable/components/general';
import { isSameDay } from 'date-fns';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { toZonedTime } from 'date-fns-tz';
import { FormatMessageParams } from 'helpers/hooks';
import { SoftCutoffsResponse } from 'helpers/services/shamrock';

export interface RemainingDays {
  days: number;
  hours: number;
  minutes: number;
  orderByDate: Date;
}

export type DeliveryDatesProps = {
  dates: Date[];
  orderBy: RemainingDays;
};

export interface UseGetDeliveryDatesReturnType {
  deliveryDates: DeliveryDatesProps;
  renderRemainingDays: (
    remainingDays: RemainingDays,
    formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
  ) => string;
  loading: boolean;
}

let current = new Date();

// use map to get the name of the day as softcutoffs api brings day in weekname
const weekdaysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDateWithCutoff = (date: Date, leadDays: number, cutoffTime?: string): Date | number => {
  if (!cutoffTime || !date) {
    return 0;
  }

  const stringDate = date.toISOString().split('T')[0];

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeConverted = toZonedTime(new Date(`${stringDate}T${cutoffTime}.000Z`), timezone);

  const utcDate = new Date(`${stringDate}T${'12:00:00'}.000Z`);
  utcDate.setDate(utcDate.getDate() - Math.abs(leadDays));

  let hour = timeConverted.getHours() === 0 ? 24 : timeConverted.getHours();
  utcDate.setHours(hour, timeConverted.getMinutes(), timeConverted.getSeconds());

  return utcDate;
};

export const calculateUpcomingDeliveryDates = (
  plan: DeliveryPlan,
  isoString: string,
  count: number = 3,
  productsLeadDays: number = 0,
  extendedCutoffISOString?: string,
  softCutoffs?: SoftCutoffsResponse,
): DeliveryDatesProps => {
  let currentDate = new Date(isoString);
  const upcomingDeliveryDates: Date[] = [];

  // Reset time for currentDate
  current.setHours(0, 0, 0);

  const hasGeocodeDeliveryDate = FEATURE_FLAG_GEOCODE_CUTOFF && !!extendedCutoffISOString;
  const geocodeDeliveryDate = hasGeocodeDeliveryDate ? new Date(extendedCutoffISOString) : null;

  plan?.DeliverySchedules.forEach((schedule) => {
    // Ensure currentDate is not before the StartDateTime
    // If geocodeDeliveryDate is provided, use that as the startDateTime
    const startDateTime = hasGeocodeDeliveryDate ? geocodeDeliveryDate : new Date(schedule.StartDateTime);
    if (currentDate < startDateTime) {
      currentDate.setTime(startDateTime.getTime());
      currentDate.setHours(0, 0, 0);
    }

    while (true) {
      if (schedule.EndDateTime && currentDate > new Date(schedule.EndDateTime)) {
        break;
      }

      if (!schedule?.CutoffTime) {
        break;
      }

      if (schedule.DeliveryDays.includes(currentDate.getDay())) {
        let leadDays = schedule.LeadDays;
        let scheduleCutoff = schedule.CutoffTime;

        // if geocode is earlyer or before current date we add as a valid delivery date.
        const isGecodeSameDayOrEarlier =
          hasGeocodeDeliveryDate &&
          (isSameDay(geocodeDeliveryDate, currentDate) || isBefore(geocodeDeliveryDate, currentDate));

        if (productsLeadDays > leadDays) {
          leadDays = productsLeadDays;
        }

        const dateWCutoff = getDateWithCutoff(currentDate, leadDays, scheduleCutoff) as Date;

        const currentWTime = new Date();

        const isCurrentBeforeCutoff = isBefore(currentWTime, dateWCutoff);

        if (isCurrentBeforeCutoff || isGecodeSameDayOrEarlier) {
          const stringDate = currentDate.toISOString().split('T')[0];
          // using 12 so it is always in the expected day in CT
          const currentDateWithTime = new Date(`${stringDate}T12:00:00.000Z`);
          upcomingDeliveryDates.push(currentDateWithTime); // Push the raw Date object
        }

        if (upcomingDeliveryDates.length >= count) {
          return upcomingDeliveryDates;
        }
      }

      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
  });

  // Sort the upcoming delivery dates in ascending order
  upcomingDeliveryDates.sort((a, b) => a.getTime() - b.getTime());

  const orderBy = getOrderByValues(upcomingDeliveryDates[0], plan, geocodeDeliveryDate, softCutoffs);

  return {
    dates: upcomingDeliveryDates,
    orderBy,
  };
};

const getOrderByValues = (
  date: Date,
  plan: DeliveryPlan,
  geocodeDeliveryDate?: Date,
  softCutoffs?: SoftCutoffsResponse,
): RemainingDays => {
  if (!date) {
    return {} as RemainingDays;
  }
  const dayOfWeekNumber = date.getDay();

  const hasGeocodeDeliveryDate = FEATURE_FLAG_GEOCODE_CUTOFF && !!geocodeDeliveryDate;
  let matchingSchedule: DeliverySchedule = null;

  // find the schedule that matches the first date
  plan?.DeliverySchedules.forEach((schedule) => {
    if (schedule.DeliveryDays.includes(dayOfWeekNumber)) {
      matchingSchedule = schedule;
      return;
    }
  });

  if (matchingSchedule && !!matchingSchedule?.CutoffTime) {
    let hasSoftCutoff = false;
    let softCutoffInfo = null;
    if (!!softCutoffs) {
      softCutoffInfo = softCutoffs?.dailyCutoffDetails?.find(
        (cutoff) => cutoff.dayOfWeek === weekdaysName[dayOfWeekNumber],
      );
      hasSoftCutoff = !!softCutoffInfo || !!softCutoffs?.primaryCutoffTime;
    }

    let leadDays = matchingSchedule.LeadDays || 0;
    const currentWTime = new Date();

    // remove lead days to allow same day delivery with geocodeCutoof
    const shouldRemoveLeadDays =
      hasGeocodeDeliveryDate &&
      geocodeDeliveryDate.getDate() <= date.getDate() &&
      date.getDate() == currentWTime.getDate();

    if (shouldRemoveLeadDays) {
      leadDays = 0;
    }

    const cutoffTime = plan?.DeliverySchedules[0]?.CutoffTime;
    let updatedDate = getDateWithCutoff(
      date,
      leadDays,
      matchingSchedule?.CutoffTime ? matchingSchedule.CutoffTime : cutoffTime,
    ) as Date;

    // if we have a softcutoff and it is before the actual cutoff we display it
    // if the softcutoff is after the current time and before the regular cutoff, we don't display it
    if (FEATURE_FLAG_SOFT_CUTOFF && hasSoftCutoff) {
      const validSoftCutoffTime = !!softCutoffInfo?.softCutoffTime
        ? softCutoffInfo?.softCutoffTime
        : softCutoffs?.primaryCutoffTime;
      if (!!validSoftCutoffTime) {
        let softCutoffDate = getDateWithCutoff(date, softCutoffInfo.leadDays, validSoftCutoffTime) as Date;

        // if current time is between soft cutoff and regular cutoff, we don't display an orderBy
        if (isAfter(currentWTime, softCutoffDate) && isBefore(currentWTime, updatedDate)) {
          return {} as RemainingDays;
        }

        // soft cutoff is always applied if leadDays is 2 or more
        // soft cutoff is before current time and before regular cutoff, we display it
        if (leadDays >= 2 || (isAfter(softCutoffDate, currentWTime) && isBefore(softCutoffDate, updatedDate))) {
          updatedDate = softCutoffDate;
        }
      }
    }

    const isGeocodeDeliveryDate = hasGeocodeDeliveryDate && isSameDay(geocodeDeliveryDate, updatedDate);

    // if this is a geocode delivery date, we need to calculate the cutoff time
    // based on the geocode delivery date
    if (isGeocodeDeliveryDate && isAfter(currentWTime, updatedDate)) {
      return getGeocodeOrderByValues(currentWTime, updatedDate, geocodeDeliveryDate);
    }

    // Calculate the time difference
    const timeDifference = differenceInMinutes(updatedDate, currentWTime);

    const orderByValues = calculateTimeRemaining(timeDifference);

    // only display orderBy if date is greater than current date
    // if geocodeCutoff is before current date, we don't display an orderBy
    const isGreaterThanCurrent = isAfter(updatedDate, currentWTime);

    return {
      ...orderByValues,
      orderByDate: isGreaterThanCurrent ? (updatedDate as Date) : null,
    };
  } else {
    return {} as RemainingDays;
  }
};

function getGeocodeOrderByValues(currentWTime: Date, updatedDate: Date, geocodeDeliveryDate: Date): RemainingDays {
  // current date is greater than regular cutoff time, we just don't show an order by
  if (isAfter(currentWTime, updatedDate)) {
    return {} as RemainingDays;
  }

  const geocodeCutoffTime = geocodeDeliveryDate.toISOString().split('T')[1].split('.')[0];
  const geocodeUpdatedDate = getDateWithCutoff(geocodeDeliveryDate, 0, geocodeCutoffTime) as Date;

  let earliestDate = isAfter(geocodeUpdatedDate, updatedDate) ? updatedDate : geocodeUpdatedDate;

  // Calculate the time difference
  const geocodeTimeDifference = differenceInMinutes(earliestDate, currentWTime);

  const geocodeOrderByValues = calculateTimeRemaining(geocodeTimeDifference);

  return {
    ...geocodeOrderByValues,
    orderByDate: isAfter(earliestDate, currentWTime) ? (earliestDate as Date) : null,
  };
}

export function calculateTimeRemaining(minutes: number) {
  if (!minutes || minutes < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
    };
  }

  // Calculate the number of days, hours, and minutes
  const days = Math.floor(minutes / (24 * 60)); // Calculate the number of days
  const remainingMinutes = minutes % (24 * 60); // Calculate the remaining minutes after extracting days
  const hours = Math.floor(remainingMinutes / 60); // Calculate the number of hours
  const finalMinutes = remainingMinutes % 60; // Calculate the final minutes

  // Return the result as an object
  return {
    days,
    hours,
    minutes: finalMinutes,
  };
}

export const getPlanByStoreKey = (selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  const deliveryPlans = selectedBusinessUnit?.custom?.fields?.sfc_business_unit_delivery_plans;

  const plans: DeliveryPlan[] = deliveryPlans ? JSON.parse(deliveryPlans) : null;
  const storeKey = selectedBusinessUnit?.stores ? selectedBusinessUnit.stores[0]?.key : null;

  if (plans && storeKey) {
    const filteredPlans = plans.filter((plan) => plan.StoreKey === storeKey);
    return filteredPlans;
  }
};

export const renderRemainingDays = (
  remainingDays: RemainingDays,
  formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
) => {
  const { days, hours, minutes } = remainingDays;

  if (days) {
    return formatMessage({
      id: hours > 0 ? 'orderBy.text.days' : 'orderBy.text.days.noHours',
      values: {
        days,
        hours: hours > 0 ? hours : undefined,
      },
    });
  } else if (hours) {
    return formatMessage({
      id: minutes > 0 ? 'orderBy.text.hours' : 'orderBy.text.hours.noMinutes',
      values: {
        hours,
        minutes: minutes > 0 ? minutes : undefined,
      },
    });
  } else if (minutes) {
    const min = minutes === 0 ? 1 : minutes;
    return formatMessage({
      id: 'orderBy.text.minutes',
      values: {
        minutes: min,
      },
    });
  }
};

const initialDeliveryDates = {
  dates: [],
  orderBy: {},
} as DeliveryDatesProps;

// Get Delivery Dates and orderBy text from business unit
export const getActiveAccountDeliveryDates = ({
  activeAccount,
  leadDays = 0,
  geocodeDeliveryDate = null,
  softCutoffs = null,
}: {
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  leadDays?: number;
  geocodeDeliveryDate?: string;
  softCutoffs?: SoftCutoffsResponse;
}) => {
  const utcString = current.toISOString();

  const plans = getPlanByStoreKey(activeAccount);

  return plans?.[0]?.DeliverySchedules?.[0].DeliveryDays.length > 0
    ? calculateUpcomingDeliveryDates(plans[0], utcString, undefined, leadDays, geocodeDeliveryDate, softCutoffs)
    : initialDeliveryDates;
};

export const useGetDeliveryDates = (leadDays = 0): UseGetDeliveryDatesReturnType => {
  const { activeAccount } = useGlobal().useUserGlobal.state;
  const { softCutoffs, loading: isLoadingCutoffs } = useGlobal().useCutoffsGlobal;
  const { extendedCutoff: geocodeDeliveryDate } = useGlobal().useCutoffsGlobal;

  const deliveryDates = useMemo(() => {
    if (isLoadingCutoffs || !activeAccount?.key) {
      return null;
    }
    return getActiveAccountDeliveryDates({ activeAccount, leadDays, geocodeDeliveryDate, softCutoffs }) || null;
  }, [activeAccount, activeAccount?.key, isLoadingCutoffs, leadDays, geocodeDeliveryDate, softCutoffs]);

  return { deliveryDates, renderRemainingDays, loading: isLoadingCutoffs };
};
