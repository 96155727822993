import { NextRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import {
  APPLICATION_URL,
  AUTHENTICATION_SESSION_LIMIT_HOURS,
  AUTH_TOKEN_REFRESH_THRESHOLD,
  GENERIC_TOAST_ERROR_ID,
  TOAST_ICON,
} from 'composable/components/general';
import { ACCESS_TOKEN, AUTH, ORIGIN_PATH, REFRESH_TOKEN } from 'composable/helpers/constants';
import { add, fromUnixTime, isAfter, isEqual, sub } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import routes from 'helpers/constants/routes';
import { isSlugValid } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import { ADDITIONAL_AZURE_URL_PARAMS } from 'hooks/global/use_privateUserGlobal';
import { LoginResponse, deleteLocalStorageValuesWithoutBu } from 'frontastic/actions/account';
import { azureGetTokens, azureRefreshTokens } from 'frontastic/actions/azure/azureActions';
import { GetTokensResponse } from 'frontastic/actions/azure/types';
import { getStoreDetailsForSelectedBu } from 'frontastic/actions/customer';
import { CustomerDataProps } from './types';
import { decrypt } from '../encription';

export const handleLoginSuccess = ({
  router,
  originPath,
  activeAccount,
  customerInCT,
  browserAccessToken,
  clientIp,
  permissions,
}: {
  router: NextRouter;
  originPath: string;
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  customerInCT: any;
  browserAccessToken: string;
  clientIp: string;
  permissions: string[];
  encryptionKey: string;
}) => {
  trackShamrockUserLogin({ accessToken: browserAccessToken, clientIp });

  analyticsTrackLogin({ method: LOGIN_METHOD });

  // Call to logrocket if logrocketid is available to identify user
  if (LOGROCKET_ID) {
    logrocktIdentifyUser(customerInCT.customer, activeAccount);
  }

  if (browserAccessToken) {
    const redirectPath = originPath.toLowerCase();
    const canRedirect = isSlugValid(redirectPath);

    if (redirectPath !== routes.HOME && redirectPath !== '' && canRedirect) {
      localStorage.setItem(ORIGIN_PATH, routes.HOME);

      router.replace(
        {
          pathname: redirectPath,
          query: '',
        },
        undefined,
        { shallow: false },
      );
    } else {
      // remove the ?code from the url
      const { code } = router.query;
      if (code) {
        router.replace(
          {
            pathname: routes.HOME,
            query: '',
          },
          undefined,
          { shallow: true },
        );
      }
    }
  }

  // Delaying appcues call after login to avoid issues while resolving redirects.
  setTimeout(() => {
    // Call to appcues if appcuesid and customer information is available
    if (APPCUES_ID) {
      appcuesIdentifyAndGroupUser(customerInCT.customer, activeAccount, permissions);
    }
  }, 3000);
};

export async function getBrowserAccessToken(window: any) {
  // check if the customer is logged in,
  // if not - redirect them to shamrock login page.
  const code = new URLSearchParams(window.location.search).get('code');

  if (code) {
    // call azure api to get access token from code
    const response = await azureGetTokens(code);
    if (response.success) {
      return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresOn: response.expires_on,
      };
    }
  }

  return {
    accessToken: null,
    refreshToken: null,
    expiresOn: null,
  };
}

/**
 * Refresh the access token before the token spires.

 * @param refreshToken azure refresh token taken on get or refresh token calls
 * @param logoutUrl azure logout url
 * @param encryptionKey encryption key to decrypt user auth time
 * @param nextTimeout time in milliseconds to check the token again
 */
export async function refreshAccessToken(
  refreshToken: string,
  logoutUrl: string,
  encryptionKey: string,
  cb: (tokenResponse: GetTokensResponse) => void,
) {
  const authToken = localStorage.getItem(ACCESS_TOKEN);
  const expiresUnixTime = getExpTimeFromAccessToken(authToken);

  const expiresOn = fromUnixTime(expiresUnixTime);
  const now = new Date();
  const secondsToRenew = Math.floor(AUTH_TOKEN_REFRESH_THRESHOLD / 1000);
  const refreshTime = sub(expiresOn, { seconds: secondsToRenew });
  const refreshTimeFormatted = formatInTimeZone(refreshTime, 'UTC', 'yyyy-MM-dd HH:mm:ss');
  const nowFormatted = formatInTimeZone(now, 'UTC', 'yyyy-MM-dd HH:mm:ss');
  console.log('Time to refresh again token', { timeToRefresh: refreshTimeFormatted, now: nowFormatted });

  // Check if user login is past the session hours limit
  const encryptedAuthTime = localStorage.getItem(AUTH) || '';
  if (encryptedAuthTime && encryptionKey) {
    const storedAuthTime = decrypt(encryptedAuthTime, encryptionKey);
    const unixAuthTime = JSON.parse(storedAuthTime).auth_time;
    const authTime = fromUnixTime(unixAuthTime);

    const loginLimit = add(authTime, { hours: AUTHENTICATION_SESSION_LIMIT_HOURS });

    if (isAfter(now, loginLimit) || isEqual(now, loginLimit)) {
      // redirect user to azure logout
      deleteLocalStorageValuesWithoutBu();
      window.location.href = logoutUrl;
      return;
    }
  }

  if (isAfter(now, refreshTime) || isEqual(now, refreshTime)) {
    console.log('Refreshing token', new Date().toISOString());
    const res = await azureRefreshTokens(refreshToken);
    if (res.success) {
      localStorage.setItem(ACCESS_TOKEN, res.access_token);
      localStorage.setItem(REFRESH_TOKEN, res.refresh_token);

      cb(res);

      return;
    }
  }

  // if token expired already and refresh was not successful,
  // logout user to avoid idle session
  if (isAfter(now, expiresOn) || isEqual(now, expiresOn)) {
    // redirect user to azure logout
    deleteLocalStorageValuesWithoutBu();
    window.location.href = logoutUrl;
    return;
  }
}

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * @param origin
 * @param errorCode
 * @returns string URL to login
 */
export const getShamrockRedirectUrl = (baseAzureRedirectUrl: string, errorCode?: string, errorMessage?: string) => {
  const origin = APPLICATION_URL;
  const parameters = new URLSearchParams({
    redirect_uri: origin,
    nonce: new Date().getTime().toString(),
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  return `${baseAzureRedirectUrl}&${parameters.toString()}`;
};

export const getAzureLogoutUrl = (baseAzureLogoutUrl: string, baseAzureRedirectUrl: string, errorCode?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const parameters = new URLSearchParams({
    redirect_uri: getShamrockRedirectUrl(baseAzureRedirectUrl, errorCode),
    error: errorCode ?? 'invalid_token',
  });
  return `${baseAzureLogoutUrl}&${parameters.toString()}`;
};

export const getTokenDetails = (accessTokenValue: string) => {
  // In the JSON Web Token (JWT) standard, the "kid" (key ID) claim is a string that indicates the key that was used to digitally sign the JWT.
  // This is used to verify the au  thenticity and integrity of the JWT, and to prevent JWT token abuse.
  const splitToken = accessTokenValue.split('.');
  const headerObj = JSON.parse(atob(splitToken[0]));
  const expiryDate = JSON.parse(atob(splitToken[1])).exp * 1000;
  return {
    kid: headerObj.kid,
    expiryDate: expiryDate,
  };
};

export const initialUserDetails = {
  accessToken: '',
  userId: '',
  customer: {
    customer: {},
    businessUnits: [],
  },
  customerPermissionsFromShamrock: [],
};

export const WarehouseInitialData = { payBillUrl: '', businessUnit: '', businessSegment: '', warehouseNumber: '' };

const checkIfApiFailed = async (status: number = 200, clearAllDataAndLogOut: () => {}) => {
  if (status == 401 || status == 403) {
    return await clearAllDataAndLogOut();
  }
  return;
};

export const fetchBuStoreDetails = async (
  buStoreKey: string,
  selectedBusinessUnitKey: string,
  clearAllDataAndLogOut: () => {},
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const storeDetails = await getStoreDetailsForSelectedBu(buStoreKey, selectedBusinessUnitKey, toast, toastMessage);
    await checkIfApiFailed(storeDetails?.status, clearAllDataAndLogOut);

    if (storeDetails?.businessSegment !== '') {
      return storeDetails;
    } else {
      throw '"storeIds info" not received from getStoreDetailsForSelectedBuAPI. Maybe an issue in the accessToken. Please try logging in Incognito mode or another browser';
    }
  } catch (error) {
    console.error('Error API: fetchBuStoreDetails', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    await clearAllDataAndLogOut();
  }
};

export const selectTheAssociatedBuObjectFromBusinessUnits = (
  businessUnits: Partial<AsAssociateBusinessUnitResult>[],
  selectedBusinessUnitKey: string,
) => {
  return businessUnits?.find((businessUnit) => {
    return businessUnit['key'] == selectedBusinessUnitKey;
  });
};

export const getCustomerObject = (accessToken: string, loginResponse: LoginResponse): CustomerDataProps => {
  const { shamrockUser, commercetoolsUser, businessUnits } = loginResponse;
  return {
    accessToken: accessToken,
    userId: shamrockUser.user.userId,
    customer: {
      customer: commercetoolsUser,
      businessUnits: businessUnits,
    },
    customerPermissionsFromShamrock: shamrockUser.user.permissions.data,
  };
};

/**
 * Check localstorage accessToken and if the expiration time is still valid.
 * @returns Boolean - true if the user is logged in, false otherwise
 */
export const isLoggedIn = (): Boolean => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
  const expiresOn = getExpTimeFromAccessToken(accessToken);

  if (accessToken && expiresOn > 0) {
    const expirationTime = fromUnixTime(expiresOn);

    if (isAfter(expirationTime, new Date())) {
      return true;
    } else {
      // We have an expired access token, to avoid any issue we clear the data
      deleteLocalStorageValuesWithoutBu();
    }
  }

  return false;
};

/**
 * get expiration time from a jwt access token
 */
export const getExpTimeFromAccessToken = (accessToken: string) => {
  if (!accessToken) {
    return '';
  }
  try {
    const jwtData = JSON.parse(atob(accessToken.split('.')[1]));
    return jwtData?.exp;
  } catch (error) {
    console.error('failed to parse accessToken');
    return '';
  }
};

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */

// Add more fields here as needed
export interface GetServerShamrockRedirectUrlParams {
  redirectTo: string;
}

export const getServerShamrockRedirectUrl = (
  origin: string,
  errorCode?: string,
  errorMessage?: string,
  params?: GetServerShamrockRedirectUrlParams,
) => {
  const shamrockUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL);

  // stringify the params object to pass it as a query param
  const paramsAsString = JSON.stringify(params);

  const parameters = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.NEXT_PUBLIC_SHAMROCK_CLIENT_ID,
    redirect_uri: origin,
    scope: process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL_SCOPE,
    nonce: new Date().getTime().toString(),
    [ADDITIONAL_AZURE_URL_PARAMS]: paramsAsString,
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  shamrockUrl.search = parameters.toString();

  return shamrockUrl.toString();
};

/**
 * Finish user Azure session and redirect to Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */
export const getServerAzureLogoutUrl = (errorCode?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const AzureUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_AZURE_LOGOUT);
  const parameters = new URLSearchParams({
    redirect_uri: getServerShamrockRedirectUrl(APPLICATION_URL, errorCode),
    error: errorCode ?? 'invalid_token',
  });
  AzureUrl.search = parameters.toString();
  return AzureUrl.toString();
};
