const routes = {
  HOME: '/',
  CART: '/cart',
  CHECKOUT: '/checkout',
  CHECKOUT_SUCCESS: '/checkout/success',
  ORDER_GUIDE_EDIT: '/order-guide/edit',
  ORDER_GUIDE_MANAGEMENT: '/order-guide/management',
  EDIT_ORDER_CHECKOUT: '/order/checkout',
  EDIT_ORDER_PAGE: '/order/edit/', // Add orderNumber to the end of url
  ORDER_DETAIL_PAGE: '/order/', // Add orderNumber to the end of url
  ORDER_LIST_PAGE: '/orders',
  ACCOUNTS: '/accounts',
  NOT_FOUND: '/not-found',
  PUBLIC_NOT_FOUND: '/public/not-found',
  CATALOG: '/category',
  CATALOG_CATEGORIES_ALL: 'category/all',
  PDP: '/product',
  PUBLIC_PDP: '/public/product', // Add sku to the end of url
  SEARCH_RESULTS: '/search',
  ORDER_GUIDE: '/order-guide',
  REDIRECT_FROM_SO: '/redirect-from-so',
  PUBLIC_ERROR_PAGE: '/public/error',
  UNAUTHORIZED_ERROR_PAGE: '/public/error?login=unauthorized',
  SUPER_USER_ACCOUNTS: '/su/accounts',
};

export default routes;
