import { useState, useMemo, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import {
  FEATURE_FLAG_GEOCODE_CUTOFF,
  FEATURE_FLAG_SPLIT_ORDER,
  FEATURE_FLAG_SPLIT_ORDER_EDIT_ORDER,
} from 'composable/components/general';
import { getDeliveryCutoff } from 'composable/components/product-card-v2/helpers';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { isAfter } from 'date-fns/isAfter';
import { useProductExtraData } from 'helpers/hooks/useProductExtraData';
import { getProductsMaxLeadDays } from 'helpers/utils/getProductsMaxLeadDays';
import {
  useGetDeliveryDates,
  calculateTimeRemaining,
  getPlanByStoreKey,
  calculateUpcomingDeliveryDates,
  DeliveryDatesProps,
} from './useGetDeliveryDates';
import { createOrderGroup } from '../utils/create-order-group';
import { findFurthestDates } from '../utils/find-furthest-dates';
import { mapLineItemsToProducts } from '../utils/map-line-items-to-products';
import { partitionProductsByCutoff } from '../utils/partition-products-by-cutoff';

export type LineItemsWithDeliveryDates = {
  deliveryDates: DeliveryDatesProps;
  lineItems: LineItem[] | OrderDetailLineItem[];
};

type UseSplitOrderReturnType = { isSplitOrder: boolean; orderGroups: LineItemsWithDeliveryDates[] };

export const SECONDARY_ORDER_GROUP_INDEX = 1;

type UseSplitOrderParams = {
  isEditOrder: boolean;
  lineItems: LineItem[] | OrderDetailLineItem[];
  setCutOffDeliveryDates: Dispatch<SetStateAction<DeliveryDatesProps>>;
};

export function useSplitOrder({
  isEditOrder,
  lineItems,
  setCutOffDeliveryDates,
}: UseSplitOrderParams): UseSplitOrderReturnType {
  const [orderGroups, setOrderGroups] = useState<LineItemsWithDeliveryDates[]>([]);
  const { activeAccount } = useGlobal().useUserGlobal.state;
  const skus = lineItems?.map((item) => item.productNumber || item?.variant?.sku);
  const { extraProductData } = useProductExtraData(skus);
  const productsLeadDays = getProductsMaxLeadDays(extraProductData);
  // Partition products by cutoff presence
  const { productsWithoutCutoff, productsWithCutoff } = useMemo(
    () => partitionProductsByCutoff(extraProductData),
    [skus],
  );
  const { deliveryDates: normalDeliveryDates } = useGetDeliveryDates(null);
  const { deliveryDates: businessDeliveryDates } = useGetDeliveryDates(
    productsWithoutCutoff?.length === 0 ? productsLeadDays : 0,
  );
  const softCutoffs = useGlobal().useCutoffsGlobal.softCutoffs;
  const geocodeDeliveryDate = useGlobal().useCutoffsGlobal.extendedCutoff;

  const getCommonData = useCallback(() => {
    // Determine products with delivery dates later than the first available business delivery date
    const datesToConsider = productsWithCutoff.length === skus.length ? normalDeliveryDates : businessDeliveryDates;
    const productsWithLaterDeliveryDate = productsWithCutoff.filter((product) => {
      const productDeliveryDate = getDeliveryCutoff(product, activeAccount, datesToConsider?.dates[0]);
      return isAfter(productDeliveryDate.deliveryDay, datesToConsider?.dates[0]);
    });

    // Compute the furthest cutoff and delivery dates among the filtered products
    const { furthestCutoffDate, furthestDeliveryDate } = findFurthestDates(
      productsWithLaterDeliveryDate,
      activeAccount,
      datesToConsider,
    );

    // Filter productsWithCutoff to exclude those in productsWithLaterDeliveryDate
    const eligibleProductsWithCutoff = productsWithCutoff.filter(
      (product) => !productsWithLaterDeliveryDate.includes(product),
    );

    // Combine productsWithoutCutoff and eligibleProductsWithCutoff for mapping
    const eligibleProductsForPrimaryOrder = [...productsWithoutCutoff, ...eligibleProductsWithCutoff];

    const { furthestCutoffDate: primFurthestCutoffDate, furthestDeliveryDate: primFurthestDeliveryDate } =
      findFurthestDates(eligibleProductsForPrimaryOrder, activeAccount, datesToConsider);

    // Map line items to products for primary and secondary orders
    const primaryOrderLineItems = mapLineItemsToProducts(eligibleProductsForPrimaryOrder, lineItems);
    const secondaryOrderLineItems = mapLineItemsToProducts(productsWithLaterDeliveryDate, lineItems);

    const plans = getPlanByStoreKey(activeAccount);

    // dates for the primary group
    const primLeadDays = getProductsMaxLeadDays(eligibleProductsForPrimaryOrder);
    const primDates =
      calculateUpcomingDeliveryDates(
        plans[0],
        primFurthestDeliveryDate?.toISOString(),
        undefined,
        primLeadDays,
        geocodeDeliveryDate,
        softCutoffs,
      )?.dates || [];

    let primOrderBy = null;

    const currentDate = new Date();
    const validGeocodeDeliveryDate =
      FEATURE_FLAG_GEOCODE_CUTOFF && !!geocodeDeliveryDate && isAfter(new Date(geocodeDeliveryDate), currentDate);

    if (
      ((secondaryOrderLineItems.length > 0 && primaryOrderLineItems.length > 0) ||
        productsWithLaterDeliveryDate.length === 0) &&
      !validGeocodeDeliveryDate
    ) {
      primOrderBy = normalDeliveryDates.orderBy;
    } else if (!!primFurthestCutoffDate) {
      primOrderBy = {
        ...calculateTimeRemaining(differenceInMinutes(primFurthestCutoffDate, new Date())),
        orderByDate: primFurthestCutoffDate,
      };
    }

    // Determine additional details for the secondary order group
    const secondaryLeadDays = getProductsMaxLeadDays(productsWithLaterDeliveryDate);
    const dates =
      calculateUpcomingDeliveryDates(
        plans[0],
        furthestDeliveryDate.toISOString(),
        undefined,
        secondaryLeadDays,
        null,
        softCutoffs,
      )?.dates || [];
    const orderBy = {
      ...calculateTimeRemaining(differenceInMinutes(furthestCutoffDate, new Date())),
      orderByDate: furthestCutoffDate ?? new Date(),
    };

    const items = [primaryOrderLineItems, secondaryOrderLineItems];

    return {
      items,
      deliveryDates: { primary: { dates: primDates, orderBy: primOrderBy }, secondary: { dates, orderBy } },
      hasDelayedProducts: productsWithLaterDeliveryDate?.length > 0,
    };
  }, [
    geocodeDeliveryDate,
    lineItems,
    extraProductData,
    activeAccount,
    normalDeliveryDates,
    businessDeliveryDates,
    softCutoffs,
  ]);

  const processSplitOrder = (): LineItemsWithDeliveryDates[] => {
    const { items, deliveryDates, hasDelayedProducts } = getCommonData();
    const { primary: primaryDeliveryDates, secondary: secondaryDeliveryDates } = deliveryDates;
    const [primary, secondary] = items;
    const primaryOrderGroup = createOrderGroup(primary, primaryDeliveryDates);
    const secondaryOrderGroup = createOrderGroup(secondary, secondaryDeliveryDates);
    const orderGroups = [primaryOrderGroup, secondaryOrderGroup];

    const orderGroupsWithItems = orderGroups.filter(
      (group) => group.lineItems?.length > 0,
    ) as LineItemsWithDeliveryDates[];

    hasDelayedProducts && orderGroupsWithItems.length !== 2
      ? setCutOffDeliveryDates(secondaryDeliveryDates)
      : setCutOffDeliveryDates(primaryDeliveryDates);

    return orderGroupsWithItems;
  };

  const processSingleOrder = () => {
    const { items } = getCommonData();
    return [createOrderGroup(items.flat(), businessDeliveryDates)] as LineItemsWithDeliveryDates[];
  };

  useEffect(() => {
    if (!businessDeliveryDates?.dates?.length || !extraProductData.length || !activeAccount) {
      return;
    }
    const orders = (isEditOrder ? FEATURE_FLAG_SPLIT_ORDER_EDIT_ORDER : FEATURE_FLAG_SPLIT_ORDER)
      ? processSplitOrder()
      : processSingleOrder();
    setOrderGroups(orders);
  }, [extraProductData, activeAccount, businessDeliveryDates, lineItems, isEditOrder, softCutoffs]);

  const isSplitOrder = useMemo(() => orderGroups.length > 1, [orderGroups]);

  return { isSplitOrder, orderGroups };
}
