import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { GetServerShamrockRedirectUrlParams } from 'composable/helpers/utils/use-user-utils';
import { ADDITIONAL_AZURE_URL_PARAMS } from '.';

/**
 * Validates accessToken is a valid string and has not expired
 * for refreshToken don't add the expiration check
 *
 * @param accessToken string accessToken or refreshToken
 * @param expiration string expiration time in unix timestamp (optional)
 * @returns boolean
 */
export function validateAccessToken(accessToken?: string, expiration?: number): boolean {
  if (expiration) {
    const expirationTime = new Date(expiration * 1000);
    const currentTime = new Date();

    if (expirationTime < currentTime) {
      return false;
    }
  }

  return !!(accessToken && accessToken.length > 5);
}

//fetch selected account details
export function selectedAccountDetails(activeAccountKey, accountList = []): Partial<AsAssociateBusinessUnitResult> {
  const accountDetails = accountList.find((bu) => bu.key === activeAccountKey) || accountList[0];
  return accountDetails;
}

export function getAdditionalAzureUrlParams(redirectUrl: string): GetServerShamrockRedirectUrlParams {
  // Parse the URL and extract the query parameters
  const urlParams = new URLSearchParams(redirectUrl.split('?')[1]);

  // Get the additional url parameters and parse it as a JSON object
  const additionalParams = urlParams.get(ADDITIONAL_AZURE_URL_PARAMS);
  const additionalParamsParsed: GetServerShamrockRedirectUrlParams = JSON.parse(additionalParams);

  return additionalParamsParsed;
}
