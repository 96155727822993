import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { FEATURE_FLAG_OFFLINE_QUEUE } from 'composable/components/general';
import { useOfflineDetector } from 'helpers/hooks/useOfflineDetector';
import { SEPARATOR_FOR_QUEUE } from './global/use_privateCartGlobal/utils';
import { logQueue } from 'helpers/utils/logQueue';

type QueueAction = {
  id: string;
  action: () => Promise<any>;
};

const shouldAddToQueue = FEATURE_FLAG_OFFLINE_QUEUE ? true : false;

export const useQueue = () => {
  const isProcessingQueueRef = useRef(false);
  const queueRef = useRef<QueueAction[]>([]);
  const [queueState, setQueueState] = useState<QueueAction[]>([]);
  const router = useRouter();
  const [showUsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const { isOffline } = useOfflineDetector({ onReconnect: () => setShowUnsavedChanges(false) });
  const isOnline = useMemo(() => !isOffline, [isOffline]);

  useEffect(() => {
    if (queueState.length > 0 && isOnline) {
      processQueue();
    }
  }, [queueState, isOnline]);

  const syncQueueStateWithRef = () => {
    setQueueState([...queueRef.current]);
  };

  const addActionToQueue = useCallback(
    ({ id, action }: QueueAction) => {
      const [newAction, sku] = id.split(SEPARATOR_FOR_QUEUE);
      const existingActionToItem = queueRef.current.findIndex((item) => item.id.includes(sku));

      //In this way we handle the use case that the user tries to add the same product but with different quantity
      if (existingActionToItem !== -1) {
        const { id: existingId } = queueRef.current[existingActionToItem];
        const [existingOldAction] = existingId.split(SEPARATOR_FOR_QUEUE);
        logQueue(`Updating queue action from ${existingOldAction} to ${newAction} for product ${sku} `);
        queueRef.current[existingActionToItem] = { id, action };
      } else {
        logQueue(`Adding action ${newAction} to queue for product ${sku}`);
        queueRef.current.push({ id, action });
      }
      syncQueueStateWithRef();
    },
    [queueRef.current],
  );

  const processQueue = useCallback(async () => {
    if (isProcessingQueueRef.current || queueRef.current.length === 0) {
      return;
    }
    logQueue('Processing queue');

    isProcessingQueueRef.current = true;

    while (queueRef.current.length > 0) {
      const nextAction = queueRef.current.shift();
      try {
        logQueue(`Processing action ${nextAction.id}`);
        await nextAction.action();
      } catch (error) {
        logQueue(`Error processing action ${nextAction.id}`);
        break;
      }
    }

    isProcessingQueueRef.current = false;
    syncQueueStateWithRef();
  }, [queueRef.current]);

  const removeActionFromQueue = useCallback(
    (id: string) => {
      if (!FEATURE_FLAG_OFFLINE_QUEUE) {
        return;
      }
      logQueue(`Removing action ${id} from queue`);
      const initialLength = queueRef.current.length;
      queueRef.current = queueRef.current.filter((item) => item.id !== id);
      syncQueueStateWithRef();
      return queueRef.current.length < initialLength;
    },
    [queueRef.current],
  );

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (shouldAddToQueue && queueRef.current.length > 0) {
        logQueue(`Existing actions in queue, ${JSON.stringify(queueRef.current)}`);
        setShowUnsavedChanges(true);
        setRedirect(url);
        router?.events.emit('routeChangeError');
        throw 'Route change aborted due to unsaved changes';
      }
    };

    router?.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router?.events.off('routeChangeStart', handleRouteChange);
    };
  }, [shouldAddToQueue, queueRef.current]);

  const redirectWithoutSaving = () => {
    if (redirect) {
      window.location.replace(redirect);
      setRedirect('');
    }
  };

  return {
    addActionToQueue,
    shouldAddToQueue,
    removeActionFromQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
  };
};
